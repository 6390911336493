<template>
  <b-card>
    <g-form @submit="save">

      <b-row>
        <!-- code  -->
        <b-col
          v-if="this.$route.params.id > 0"
          md="4"
        >
          <b-form-group>
            <g-field
              id="code"
              type="number"
              name="code"
              label-text="transactionNumber"
              readonly
              :value.sync="selectedItem.code"
            />
          </b-form-group>
        </b-col>

        <!-- transaction date  -->
        <b-col
          v-if="!currentBranch.setDefaultDate"
          md="4"
        >
          <g-field
            :value="getDate(selectedItem.transactionDate)"
            label-text="date"
            disabled
            name="transactionDate"
          />
        </b-col>

        <b-col
          v-else
          md="4"
        >
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="transactionDate"
          />
        </b-col>

        <!-- transaction time  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              label-text="transactionTime"
              name="transactionTime"
              readonly
              :value.sync="selectedItem.transactionTime"
            />
          </b-form-group>
        </b-col>

        <!-- student  -->
        <b-col
          md="4"
        >
          <student-autocomplete ref="autoComlete"
                    name="student"
                    rules="required"
                    :value.sync="selectedItem.studentId"
                    url="students/getStudentsTaxLookup"
                    :display-item="{
                      id: selectedItem.studentId,
                      uid: selectedItem.studentId,
                      arabicName: selectedItem.studentArabicName,
                    }"
                    @change:action="(val) => resetChoices(val)"
                  />
        </b-col>

        <!-- discount type -->
        <b-col md="4">
          <label
            style="font-size: 14px;margin-bottom: 7px;"
            for="discountType"
          >
            {{ $t('discountType') }}
            <feather-icon
              v-b-tooltip.hover="$t('discountTypeHint')"
              icon="InfoIcon"
              class="mx-25 clickable"
            />
          </label>
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              name="discountType"
              :options="discounts"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.discountId"
              @change="(v) => {
                customizeBasedOnType(v);
              }"
            />
          </b-form-group>
        </b-col>

        <!-- service name -->
        <b-col
          v-show="helper.isPercentage && helper.invoice.serviceNameAr"
          md="4"
        >
          <b-form-group>
            <g-field
              label-text="serviceRelatedToDiscount"
              name="serviceName"
              readonly
              :value.sync="helper.invoice.serviceNameAr"
            />
          </b-form-group>
        </b-col>

        <!-- invoice code -->
        <b-col
          v-show="helper.isPercentage && helper.invoice.invoiceCode"
          md="4"
        >
          <b-form-group>
            <g-field
              label-text="lastInvoiceRelatedToServiceforStudent"
              name="invoiceCode"
              readonly
              :value.sync="helper.invoice.invoiceCode"
            />
          </b-form-group>
        </b-col>

        <!-- discount percentage -->
        <b-col
          v-show="helper.isPercentage && helper.invoice.discountPercentage"
          md="4"
        >
          <b-form-group>
            <g-field
              label-text="discountPercentage"
              name="discountPercentage"
              readonly
              :value.sync="helper.sPercentageValue"
            />
          </b-form-group>
        </b-col>

        <!-- service value -->
        <b-col
          v-show="helper.isPercentage && helper.invoice.serviceCost"
          md="4"
        >
          <b-form-group>
            <g-field
              label-text="serviceValue"
              name="serviceValue"
              type="number"
              readonly
              :value.sync="helper.invoice.serviceCost"
            />
          </b-form-group>
        </b-col>

        <!-- discount value  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              label-text="discountValue"
              type="number"
              rules="required"
              :value.sync="selectedItem.value"
              @keydown="() => helper.isValueChanged = true"
            />
          </b-form-group>
        </b-col>

        <!-- "original value  -->
        <b-col
          v-if="selectedItem.value"
          md="4"
        >
          <b-form-group>
            <g-field
              field="number"
              label-text="originalValue"
              type="number"
              disabled
              :value.sync="selectedItem.originalValue"
            />
          </b-form-group>
        </b-col>

        <!-- tax value  -->
        <b-col
          v-if="selectedItem.value"
          md="4"
        >
          <b-form-group>
            <g-field
              field="number"
              label-text="taxValue"
              type="number"
              disabled
              :value.sync="selectedItem.taxValue"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- notes -->
      <b-row class="mb-1">
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-modal
        ref="inv-modal"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="xl"
        hide-footer
        :title="$t('allInvoice')"
      >
        <b-row>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("allocationValue") }} :
              <span class="text-primary">{{
                id
                  ? selectedItem.value
                  : selectedItem.value
              }}</span>
            </h2>
          </b-col>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("allocatedValue") }} :
              <span class="text-primary">{{ allPaid }}</span>
            </h2>
          </b-col>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("remining") }} :
              <span class="text-primary">{{
                id
                  ? (selectedItem.value - lastPaid) - allPaid
                  : selectedItem.value - allPaid
              }}</span>
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-checkbox
              v-model="helper.showNotCompletedOnly"
              name="check-button"
              switch
              inline
              class="mt-1 mb-2"
            >
              {{ $t('showNotAllocatedCompletelyOnly') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <g-table
          ref="items-table"
          v-model="itemsArray"
          :items="invoiceProvider"
          :columns="tableColumns"
          :is-busy="isTableBusy"
          :noAction="true"
          perPage="25"
          :totalRows="totalRows"
          :createButton="{ visiable: false }"
          :excelButton="{ visiable: false }"
          :pdfButton="{ visiable: false }"
          :searchInput="{ visiable: false }"
          :tbody-tr-class="
            (item) => {
              if (item && item.net - item.paid === 0) return 'table-success';
            }
          "
        >
          <template #actions="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.value"
              type="number"
              :disabled="item.net - item.paid === 0"
              :rules="`min_value:0|max_value:${item.remainder}`"
              size="sm"
              :style="{ width: '100px' }"
              :short-desc="true"
              @keyup="
                (v) => {
                  updateFinancialTransactionAllocations(item, itemsArray);
                  if (item.value > item.remainder) {
                    moreThan = true;
                  } else {
                    moreThan = false;
                  }
                }
              "
            />
          </template>
        </g-table>
        <hr/>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            data-action-type="save"
            :disabled=" allPaid === 0 || moreThan"
            variant="gradient-primary"
            @click="() => {
              if (!isValidVoucherValuePaid()) { return }
              save()
            }"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-modal>
      <!-- operations -->
      <b-row>
        <b-col
          md="4"
          class="cancel-allocation d-flex justify-content-start"
        >
          <b-button
            v-if="id && selectedItem.studentDiscountAllocations.length > 0"
            variant="adn"
            @click="
              () => {
                selectedItem.studentDiscountAllocations = [];
                save('cancel');
              }
            "
          >
            <feather-icon
              icon="ZapOffIcon"
              class="mr-50"
            />
            {{ $t("cancelAlloucation") }}
          </b-button>
        </b-col>
        <b-col
          md="8"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            class="mr-1"
            type="submit"
            data-action-type="save"
            variant="gradient-primary"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t("save") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            :disabled="!selectedItem.value"
            class="mr-1"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t("saveAndPrint") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            :disabled="!selectedItem.studentId"
            variant="gradient-primary"
            data-action-type="saveAndCustomize"
            @click="
              () => {
                openInvModal();
                allPaid = 0;
              }
            "
          >
            <feather-icon
              icon="ZapIcon"
              class="mr-50"
            />
            {{ $t("saveAndCustomize") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    GTable,
    StudentAutocomplete,
  },
  mixins: [
    reportMixin,
  ],
  props: ['id'],
  data() {
    return {
      items: [],
      selectedItem: {
        value: 0,
        originalValue: 0,
        taxValue: 0,
        studentDiscountAllocations: []
      },
      remainingBalance: 0,
      studentDiscountAllocations: [],
      alldata: [],
      filter: {
        transactionType: '',
        itemTransactionPaymentNature: '3',
        id: null,
        studentId: null,
        storeId: null,
        ItemId: null,
        FromDate: null,
        toDate: null,
        orderClause: null,
        category: '',
        Code: null
      },
      isTableBusy: false,
      totalRows: 0,
      totalRemainder: 0,
      totalPaid: 0,
      totalDiscount: 0,
      totalNet: 0,
      totalAllocatedValue: 0,
      itemsArray: [],
      hideActions: false,
      moreThan: false,
      isSelectedStudentTaxable: false,
      services: [],
      invoices: [],
      students: [],
      discounts: [],
      allPaid: 0,
      lastPaid: 0,
      helper: {
        isPercentage: false,
        sPercentageValue: '',
        isValueChanged: false,
        discount: {},
        invoice: {},
        showNotCompletedOnly: false
      }
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('invoiceCode'),
          sortable: true,
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('invoiceDate'),
          sortable: true,
        },
        {
          key: 'lineSerial',
          field: 'lineSerial',
          label: this.$t('serialIndicator'),
          sortable: true,
        },
        {
          key: this.isRight ? 'serviceName' : 'serviceName',
          field: this.isRight ? 'serviceName' : 'serviceName',
          label: this.$t('serviceName'),
          sortable: true,
        },
        {
          key: 'net',
          field: 'net',
          label: this.$t('net'),
          sortable: true,
          footer: () => this.fraction(this.totalNet)
        },
        {
          key: 'allocatedValue',
          field: 'allocatedValue',
          label: this.$t('paid'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalPaid)
        },
        {
          key: 'allocateDiscount',
          field: 'allocateDiscount',
          label: this.$t('discount'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalDiscount)
        },
        {
          key: 'remainder',
          field: 'remainder',
          label: this.$t('remaindered'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalRemainder)
        },
        {
          key: 'actions',
          label: this.$t('actions'),
          sortable: false,
          thStyle: { width: '200px' },
        },
      ];
    },
  },
  watch: {
    'selectedItem.studentId'(val) {
      if (!val) {
        this.resetChoices();
      }
    },

    'selectedItem.value'(val) {
      if (!this.helper.isValueChanged) return;
      this.setOriginalAndTaxValue(val);
    },
    'selectedItem.studentDiscountAllocations'(oldval, newval) {
      newval.forEach(data => {
        const filterdItem = this.itemsArray.find((val) => val.lineSerial === data.lineSerial && val.serviceId === data.serviceId);
        const number = Number(data.allocatedValue) ? Number(data.value) - Number(data.allocatedValue) : Number(data.value);
        const mainVal = this.id ? (this.selectedItem.value - this.lastPaid) - this.allPaid : this.selectedItem.value - this.allPaid
        if ((Number(filterdItem.remainder) !== 0 && (number > Number(filterdItem.remainder) || Number(data.value) > Number(filterdItem.remainder))) || mainVal < 0) {
          this.moreThan = true;
          this.doneAlert({ text: this.$t('allocationValueIsMoreThanMainValue'), type: 'warning' });
        } else if ((number <= Number(filterdItem.remainder) && Number(filterdItem.remainder) !== 0) || mainVal < 0) {
          this.moreThan = false;
        }
      });
    },
    'helper.showNotCompletedOnly'(isSome) {
      this.invoiceProvider = isSome
                        ? this.itemsArray.filter((x) => parseFloat(x.remainder) > 0)
                        : this.itemsArray
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.loadObj();
    this.selectedItem.transactionDate = this.today;
    this.selectedItem.transactionTime = this.getTime();
  },

  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    loadObj() {
      this.getDiscounts();
      if (this.id > 0) {
          this.getData();
        }
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
      params += this.getFilterObj(this.filter);
      if (this.filter.studentId === null) {
        this.filter.studentId = this.selectedItem.studentId;
      }
      this.isTableBusy = true;
      this.get({ url: `Invoices/services/${this.filter.studentId}${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          this.totalNet = 0;
          this.totalRemainder = 0;
          this.totalPaid = 0;
          this.totalDiscount = 0;
          data.forEach((item) => {
            item.paid = 0;
            this.totalPaid += (item.paid + item.allocatedValue);
            this.totalNet += item.net;
            item.allocatedValue = item.allocatedValue ? item.allocatedValue : 0;
            this.totalDiscount = item.allocateDiscount ? item.allocateDiscount : 0;
            this.totalRemainder += (item.net - (item.paid + item.allocatedValue + item.allocateDiscount));
            item.paid = this.fraction(item.paid + item.allocatedValue);
            item.remainder = this.fraction(item.net - (item.allocatedValue + item.allocateDiscount));
            item.transactionDate = this.getDate(item.transactionDate);
            item.net = Number(this.fraction(item.net));
            item.value = 0;
          });
          this.itemsArray = data;
          if (this.alldata.length) {
            this.selectedItem.studentDiscountAllocations.forEach(elem => {
              const selectedItem = this.alldata.find((val) => val.id === elem.invoiceId);
              const selectedmain = data.find((val) => val.id === selectedItem.id);
              if (!selectedmain) return data.push(selectedItem);
            });
          }
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    updateFinancialTransactionAllocations(item, items) {
      this.allPaid = 0;
      items.forEach(row => {
        this.allPaid += parseFloat(row.value) || 0;
      });
      const selectedItem = this.selectedItem.studentDiscountAllocations.find((val) => val.lineSerial === item.lineSerial && val.serviceId === item.serviceId && val.invoiceId === item.id);
      if (!selectedItem && item.value > 0) {
        this.selectedItem.studentDiscountAllocations.push(
          {

            lineSerial: item.lineSerial,
            serviceId: item.serviceId,
            allocatedValue: item.allocatedValue,
            invoiceId: item.id,
            value: item.value,
            studentDiscountId: this.id ? this.id : 0
          }
        )
      } else if (item.value === 0 && selectedItem) this.selectedItem.studentDiscountAllocations = this.selectedItem.studentDiscountAllocations.filter((val) => val !== selectedItem);
      else if (selectedItem) {
        const indx = this.selectedItem.studentDiscountAllocations.indexOf(selectedItem);
        this.selectedItem.studentDiscountAllocations.splice(indx, 1);
        this.selectedItem.studentDiscountAllocations.push({
          lineSerial: item.lineSerial,
          serviceId: item.serviceId,
          invoiceId: item.id,
          value: item.value,
          studentDiscountId: this.id ? this.id : 0,
          allocatedValue: item.allocatedValue,

        });
      }
      this.mainVal = 0;
      this.selectedItem.studentDiscountAllocations.forEach(data => {
        data.value = data.value ? data.value : 0;
        this.mainVal += data.value;
        this.totalAll = Number(this.selectedItem.value) - this.mainVal;
        this.totalAllocatedValue = this.totalAll;
        var val = this.id ? (Number(this.selectedItem.value) - this.lastPaid) - this.allPaid : Number(this.selectedItem.value) - this.allPaid;
        if (val < 0) {
          this.moreThan = true;
          this.doneAlert({ text: this.$t('allocationValueIsMoreThanMainValue'), type: 'warning' });
        } else {
          this.moreThan = false;
        }
      });
     // this.closeInvModal();
    },
    openInvModal() {
      this.helper.showNotCompletedOnly = false;
      this.totalAllocatedValue = this.selectedItem.value;
      if (this.id > 0 && this.selectedItem.studentId) {
        this.getAllInv();
      } else {
        this.$refs['inv-modal'].show();
      }
    },
    getAllInv() {
      this.get({ url: `Invoices/services/${this.selectedItem.studentId}?pageNumber=${1}&pageSize=${50}&studentId=${this.selectedItem.studentId}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          this.totalNet = 0;
          this.totalRemainder = 0;
          this.totalPaid = 0;
          data.forEach((item) => {
            item.paid = 0;
            this.totalPaid += (item.paid + item.allocatedValue);
            this.totalNet += item.net;
            item.allocatedValue = item.allocatedValue ? item.allocatedValue : 0;
            item.allocatedValue += item.allocateDiscount;
            this.totalRemainder += (item.net - (item.paid + item.allocatedValue));
            item.paid = this.fraction(item.paid + item.allocatedValue);
            item.remainder = this.fraction(item.net - item.allocatedValue);
            item.transactionDate = this.getDate(item.transactionDate);
            item.net = this.fraction(item.net);
            item.value = 0;
          });
          this.alldata = data;
          // this.$refs['items-table'].refreshTable();
        }).then(() => {
          this.$refs['inv-modal'].show();
        })
        .catch(() => {
          this.isTableBusy = false;
        });
      return null;
    },
    setOriginalAndTaxValue(value) {
      if (this.isSelectedStudentTaxable || this.helper.discount.isServiceTaxable) {
        this.selectedItem.originalValue = (value / 1.15).toFixed(2);
        this.selectedItem.taxValue = (value - this.selectedItem.originalValue).toFixed(2);
      } else {
        this.selectedItem.originalValue = value;
        this.selectedItem.taxValue = 0;
      }
    },

    customizeBasedOnType(discount) {
      // get discounts based on discount selection
      this.helper.discount = this.discounts.find((val) => val.id === discount.id);
      this.helper.isPercentage = this.helper.discount.valueType === 'percentage';
      this.helper.isValueChanged = true;

      // validate that student must be selected
      if (!this.selectedItem.studentId) {
        this.doneAlert({ text: this.$t('mustChooseStudentFirst'), type: 'warning', timer: 3000 });
        this.resetChoices();
        return;
      }

      // validate that discount must related to service
      if (this.helper.isPercentage && !discount.serviceId) {
        this.doneAlert({ text: this.$t('discountNotRelatedToService'), type: 'warning', timer: 4000 });
        this.resetChoices();
        return;
      }

      if (this.helper.isPercentage) {
        // get invoice based on discount selection
        this.get({ url: `Invoices/LastInvoiceForStudent?studentId=${this.selectedItem.studentId}&discountId=${discount.id}` }).then((data) => {
          this.helper.invoice = data;

          // if service not used in invoice inform user then reset every choice
          if (!this.helper.invoice) {
            this.doneAlert({ text: this.$t('noServiceForStudent'), type: 'info', timer: 4000 });
            this.resetChoices();

            // otherwise calculate discount value based on service cost defined in invoice
          } else {
            this.selectedItem.value = this.helper.invoice.serviceCost * (discount.value / 100)
            this.helper.sPercentageValue = `${this.helper.invoice.discountPercentage} %`
          }
        })

        // means discount type is value
      } else {
        this.selectedItem.value = discount.value;
      }
    },

    resetChoices(val) {
      this.helper.isPercentage = false;
      this.selectedItem.discountId = null;
      this.helper.discount = {};
      this.helper.invoice = {};
      this.selectedItem.value = 0;
      this.isSelectedStudentTaxable = val.isTaxable
    },

    getData() {
      this.get({
        url: 'StudentDiscounts',
        id: this.$route.params.id,
      }).then((data) => {
        data.studentDiscountAllocations.forEach(item => {
          this.lastPaid += item.value;
          item.value = 0 + item.value;
        });
        this.selectedItem = data;
        this.isSelectedStudentTaxable = data.isTaxable;
      })
    },

    getDiscounts() {
      this.get({ url: 'Discounts' }).then((data) => {
        this.discounts = data;
      });
    },

    backToList() {
      this.$router.push({ name: 'studentDiscounts' });
    },

    isValidVoucherValuePaid() {
      if (this.selectedItem.value - this.allPaid > 0) {
        this.doneAlert({ text: this.$t('amountMustBeAllocated'), type: 'warning' });
        return false
      }

      // all is well
      return true
    },
    save(type) {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      // validate discount type is exists
      if (!this.selectedItem.discountId) {
        this.doneAlert({ text: this.$t('chooseDiscountTypeFirst'), type: 'warning' });
        return;
      }

      // then save
      this.selectedItem.branchId = this.branchId;
        if (this.id > 0) {
          this.update({
            url: 'StudentDiscounts',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              if (type === 'cancel') {
                this.doneAlert({ text: this.$t('allocationCancelledSuccessfully') });
              } else {
                this.doneAlert({ text: this.$t('updatedSuccessfully') });
                if (type === 'saveAndPrint') this.print(this.selectedItem.id);
              }
              this.backToList();
            });
        } else {
          this.selectedItem.transactionTime = this.getTime();
          this.create({
            url: 'StudentDiscounts',
            data: this.selectedItem,
          })
            .then((dataId) => {
              this.doneAlert({
                text: this.$t('savedSuccessfully'),
              });
              if (type === 'saveAndPrint') this.print(dataId);
              this.backToList();
            });
        }
    },

    print(itemId) {
      const reportName = this.isRight ? 'StudentDiscount-ar' : 'StudentDiscount-en';
      const printedItem = {
        id: itemId
      }
      this.printReport(reportName, printedItem);
    },
  },
};
</script>

<style>
</style>
